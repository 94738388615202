.status {
   color: #aed4eb;
   flex-basis: 30%;
}
.status h1 {
   margin-bottom: 1rem;
   text-transform: uppercase;
}
.status .red {
   color: #b1111b;
   box-shadow: none;
}
.status .black {
   color: rgb(0, 0, 0);
   box-shadow: none;
}
