.game {
   font-size: 1.8rem;
   text-align: center;
   display: grid;
   grid-template-columns: 1fr 1fr;
   padding: 8rem 3rem;
   row-gap: 2rem;
}

.board {
   position: relative;
   grid-row: span 3;
   margin: 0 auto;
   text-align: initial;
}

.cta {
   font-family: 'Nunito', -apple-system, cursive, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
   font-size: 1.2rem;
   font-weight: 600;
   padding: 1.8rem 4rem;
   text-transform: uppercase;
   cursor: pointer;
   border: none;
   background-color: #bffcfb;
   margin-top: 2rem;
   border-radius: 10px;
   box-shadow: 0px 4px 0px 0px #002726;
}
.cta:hover {
   background-color: #71e6e4;
}

.instruction {
   font-size: 1.5rem;
   text-align: left;
   color: rgb(255, 255, 255);
   width: 100%;
   padding: 0 2rem;
   margin-top: 2rem;
}

@media screen and (max-width: 600px) {
   .instruction {
      font-size: 1.2rem;
      width: 100%;
   }
   .game {
      grid-template-columns: 1fr;
   }
   .desc {
      font-size: 1.4rem;
      margin: 0 auto;
      margin-bottom: 4rem;
   }
   .cta {
      padding: 1rem 2rem;
   }

   .board {
      margin: 4rem auto;
   }
}

@media screen and (max-width: 900px) {
   .game {
      flex-direction: column;
      min-width: auto;
   }
   .desc {
      margin: 0 auto;
      margin-bottom: 4rem;
      min-width: 60%;
   }
}
