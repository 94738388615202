.score {
   justify-self: center;

   display: grid;
   justify-items: center;
   grid-template-columns: 1fr 1fr;
   width: 60%;
   border: 1px solid rgba(255, 255, 255, 0.1);
   padding: 2rem;
   background-color: rgba(255, 255, 255, 0.1);
   border-radius: 5px;
}
.player {
   width: 50%;
}
.player h4 {
   border-bottom: 1px solid rgba(255, 255, 255, 0.7);
   padding-bottom: 1rem;
}

.player p {
   padding-top: 1rem;
}

.score .cta {
   grid-column: span 2;
}
