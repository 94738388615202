#root {
   /* height: 100vh; */
   /* display: flex; */
   /* align-items: center; */
}

body {
   font-family: 'Nunito', -apple-system, cursive, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
   /* font-family: cursive; */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-image: url('./assets/blob-haikei\ \(1\).svg');
   background-size: cover;
   /* box-sizing: border-box
   ; */
}
*,
*::after,
*::before {
   margin: 0;
   padding: 0;
   box-sizing: inherit;
}
html {
   font-size: 62.5%;
   box-sizing: border-box;
}
@media screen and (max-width: 900px) {
   html {
      font-size: 50%;
   }
}
@media screen and (max-width: 1200px) {
   html {
      font-size: 55.25%;
   }
}

.clock-rotate-enter-active {
   transform: rotate(90deg);
   transition: all 300ms ease;
}

.counter-rotate-enter-active {
   transform: rotate(-90deg);
   transition: all 300ms ease;
}
